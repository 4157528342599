import React from "react";
import '../styles/Schedule.css';
import ScheduleImg from '../img/dates.png'

const ImpDates = () => {
    return (
        <div className="Schedule">

            <img src={ScheduleImg} alt="Schedule" />
        </div>
    );
};

export default ImpDates;
