import React from "react";
import "../styles/CallForPaper.css";
import CallforPaper from '../img/Call For Papers.png'


const CallForPaper = () => {
  return (
    // <div className="callforpapers">
    //   <h2>Call for Papers</h2>

    //   <p>
    //     Prospective authors are encouraged to submit their original research
    //     papers in any of the conference tracks. Only original papers which have
    //     not been published or submitted elsewhere will be considered.
    //     Submissions will undergo a rigorous peer-review process, and accepted
    //     papers will be included in the conference proceedings. The papers must
    //     be formatted strictly as per the conference template available on the
    //     website.
    //   </p>

    //   <h2>
    //     The topics of interest for submission of papers include but not limited
    //     to:
    //   </h2>

    //   <ul>
    //     <div className="list">
    //       <h4 className="sub-list">
    //         <i className="bi bi-caret-right-fill"></i>Data Analytics
    //       </h4>
    //       <li>Data Analytics Systems</li>
    //       <li>Data Representation</li>
    //       <li>Data Clustering</li>
    //       <li>Symbolic Data Analysis</li>
    //       <li>Fuzzy Data Analysis</li>
    //       <li>Soft Computing</li>
    //     </div>
    //     <div className="list">
    //       <h4 className="sub-list">
    //         <i className="bi bi-caret-right-fill"></i>Machine Learning
    //       </h4>
    //       <li>Machine Learning</li>
    //       <li>Conceptional learning</li>
    //       <li>Classification and Clustering Algorithms</li>
    //       <li>Feature Learning</li>
    //       <li>Support Vector Machines</li>
    //       <li>Neural Networks and its applications</li>
    //       <li>Deep Learning algorithms</li>
    //     </div>
    //     <div className="list">
    //       <h4 className="sub-list">
    //         <i className="bi bi-caret-right-fill"></i>Computer Vision
    //       </h4>
    //       <li>Image Rectification</li>
    //       <li>Stereo Vision</li>
    //       <li>Object Detection</li>
    //       <li>Motion Estimation</li>
    //       <li>Camera Calibration</li>
    //     </div>
    //   </ul>

    //   <h3>Publication:</h3>
    //   <p>
    //     All papers will be double blind peer reviewed. Accepted papers will be
    //     published in Springer. It is planned to publish the proceedings in
    //     Springer LNNS.
    //   </p>

    //   <h3>Paper Submission:</h3>

    //   <p>
    //     Authors should prepare their manuscripts of 12-15 pages (including all
    //     text, figures, and references) as per guidelines of Springer.
    //   </p>

    //   <h3>manuscript Uploading:</h3>

    //   <p>
    //     The manuscript has to be uploaded online on{" "}
    //     <a
    //       href="https://cmt3.research.microsoft.com/DAL2024"
    //       target="_blank"
    //       rel="noopener noreferrer"
    //     >
    //       https://cmt3.research.microsoft.com/DAL2024
    //     </a>
    //     .
    //   </p>

    //   <h3>Best Paper Awards:</h3>

    //   <p>
    //     DAL' 24 will select and present Best Paper, Best Poster and Best Student
    //     Paper Award.
    //   </p>
    // </div>
    <div  className="callforpapers">
      <img src={CallforPaper} alt="CallForPaper" />
    </div>
  );
};

export default CallForPaper;
